:root {
}

/* big hack */
.hidden {
		position: fixed;
		border: 0;
		height: 1px;
		width: 1px;
		bottom: 0;
		right: 0;
}

.event {
		height: 54px;
		padding: 3px 5px 3px 5px;
		margin: 7px;
		position: relative;

		background: white;
		border-radius: 7px;

		transition-duration: .3s;
		transition-property: height;
		overflow: hidden;

		cursor: pointer;
}

.event.selected {
		height: 110px;
}

#web .event:hover {
		padding: 2px 4px 2px 4px;
		border: 1px solid var(--main-action-color);
}
#web .event:hover .event-date {
		top: 37px;
		right: 3px;
}

.event-logo {
		height: 40px;
		line-height: 54px;
		position: absolute;
		text-align: center;
		width: 40px;
}

.event-logo-img {
		max-width:100%;
		max-height:100%;
		vertical-align: middle;
}

.event-body {
		margin-left: 47px;
		height: 54px;
		display: flex;
		justify-content: space-around;
		flex-direction: column;
}

.event-date {
		position: absolute;
		right: 4px ;
		top: 38px;
		font-size: 14px;
}
.event-date-item {
		margin: 2px;
}

.event-location {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		font-size: 13px;
		font-weight: lighter;
}

.event-title, .event-teacher {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
}

.event-title {
		font-size: 15px;
		font-weight: 700;
		line-height: 20px;
}

.event-teacher {
		font-size: 14px;
		line-height: 16px;
}

.actions {
		height: 55px;
		display: flex;
		justify-content: space-between;
		align-items: center;

}

.action-btn {
		cursor: pointer;
		border: 1px solid var(--action-color);
		background: var(--action-color);
		color: black;
		border-radius: 20px;
		font-size: 20px;
		line-height: 20px;
		padding: 9px 18px 6px 18px;

}
.action-btn:hover {
		border: 1px solid var(--main-action-color);
		background: var(--main-action-color);
		color: white;
}

.main-action-btn {
		background: white;
		border: 1px solid var(--main-action-color);
		color: var(--main-action-color);
		font-size: 16px;
		padding: 8px 20px;

}
.main-action-btn:hover {
		background: var(--main-action-color);
		color: white;
}

.action-btn:last-child {
		margin-left: 3px;
}


.action-block {
		display: flex;
}
