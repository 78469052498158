:root {
		--list-header-height: 32px;
}

.list {
		height: 100%;
		width: 410px;
		position: relative;

		margin: 15px;
}

.list-header {
		height: var(--list-header-height);

		font-weight: 700;
		font-size: 20px;
		font-family: sans-serif;
		line-height: 28px;
		text-align: center;
}

.list-body {
		height: calc(100vh - var(--list-header-height) - var(--header-height) - 70px);
		/* min-height: calc(100vh - var(--list-header-height) - var(--header-height) - 40px); */

		background: var(--color-grey);
		overflow: scroll;
		border-radius: 10px;
		border: 1px solid #80808026;
		box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
		margin: 5px;
}
