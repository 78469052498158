.block {
}
.block-header {
		top: 0;
		position: sticky;
		display: flex;
		z-index: 1;

		background: #eeeeee;
		color: #000000;
		height: 30px;
}

.block-date-wrapper {
		display: flex;
		justify-content: space-between;
		align-items: center;

		width: 135px;
		padding-left: 5px;
}

.block-date {
		line-height: 30px;
		font-size: 20px;
		font-weight: bold;
		text-align: right;
		padding-right: 5px;
		margin-left: 5px;

}
