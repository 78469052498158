#header {
		height: var(--header-height);
		background: var(--color-grey);
		display: flex;
		align-items: center;
		justify-content: space-between;
}

.header-mobile {
		background: white;
}

#header-logo {
		height: 30px;
		width: 30px;
		padding: 0px 12px;
}
#header-logo > img {
		height: inherit;
		width: inherit;
}
.header-block {
		display: flex;
		align-items: center;
}
#header-title {
		font-family: 'Space Mono', monospace;
		font-size: 20px;
		line-height: 20px;
}
.search {
		border: none;
		outline: none;
		width: 90%;
		height: 24px;
		padding-left: 6px;
		font-size: 13px;
		line-height: 13px;
}
.search-wrapper {
		background: #fff;
		border-radius: 10px;
		border: 1px solid black;
		height: 29px;
		margin: 0 6px;
		padding: 2px 0px 2px 10px;
		width: 100%;
		max-width: 500px;
}

