:root {
		--header-height: 40px;
		--color-grey: #f1f1f1;
		--color-darkgrey: #4f4f4f;
		--main-action-color: #1c8eae;
		--action-color: #99e9f2;
}

.x-clear {
		cursor: pointer;
		font-size: 24px;
}
.x-clear:hover {
		color: grey;
}



html, body {
		height: 100%;
		margin: 0;
		overflow: hidden;
}

#body {
		height: 100vh;
}

#empty {
		display: flex;
		height: 100%;
		width: 100%;
		justify-content: center;
		align-items: center;
		flex-direction: column;
}
.empty-message {
		margin-bottom: 10px;
}

#main {
		height: calc(100vh - var(--header-height));
		background: white;
		overflow: hidden;
}

#mobile {
		height: calc(100vh - var(--header-height));
		overflow: scroll;
		background: #f6f6f6;
}

#web {
		overflow-x: scroll;
		overflow-y: hidden;
		white-space: nowrap;
}

.list {
		display: inline-block;
}

/* clears the ‘X’ from Internet Explorer */
input[type=search]::-ms-clear { display: none; width : 0; height: 0; }
input[type=search]::-ms-reveal { display: none; width : 0; height: 0; }
/* clears the ‘X’ from Chrome */
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration { display: none; }
